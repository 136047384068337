// import React from 'react';
import { graphql } from 'gatsby';

import CarouselMobile from '@components/Common/CarouselMobile/index';
import SEO from '@components/Common/SEO/index';
import useResizer from '@components/extra/useResizer';
import FellowshipCarousel from '@components/Fellowship/Carousel/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useContext, useRef } from 'react';
import { Link } from 'gatsby';
import ArrowLeft from '../images/ArrowLeft.png';
import ArrowRight from '../images/ArrowRight.png';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import { GlobalStateContext } from '../context/GlobalContextProvider';

import * as styles from './AdPage.module.scss';
import { EAnchorIds } from '@src/constants/anchorLinks/index';
import { globalHistory as history } from '@reach/router';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import 'swiper/css/bundle';
import RawHTML from '@components/Common/RawHTML/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_AD_PAGE } from '@src/constants/LeadGenerationConstants/index';

export default function Adpage(props) {
  const { data: queryData } = props;
  const { adPagesYaml: data } = queryData;

  const { location, navigate } = history;
  const typeOfProgram = data.primaryProgramID;
  const sale = props.data.strapiSale?.SaleID;
  //images
  const companyImages = data.companyImages;
  const images = props.data.images?.nodes;

  // console.log('debug > ', queryData);

  const pageData = queryData.allStrapiPrograms.nodes.filter(
    (el) => data.primaryProgramID === el.PrimaryProgramID,
  )[0];

  const state = useContext(GlobalStateContext);
  const isMobile = useResizer();
  const ref = useRef();

  const companiesData = queryData.allStrapiCompanies?.nodes.reduce(
    (result, obj) => {
      result.push({
        id: obj.strapiId,
        name: obj.Name,
        url: obj.Logo.localFile,
      });
      return result;
    },
    [],
  );

  const allYoutubeThumbnail = queryData.allYoutubeThumbnail?.nodes;

  const testimonialQuotesData =
    queryData.allStrapiTestimonialWithQuotes?.nodes.reduce((result, obj) => {
      result.push({
        name: obj.graduate.Name,
        linkedin: obj.LinkedInLink,
        companyLogo: companiesData.find((val) => val.id == obj.graduate.company)
          ?.url,
        iFrame: obj.YoutubeVideoLink,
        quote: obj.Quote,
        thumbnail: allYoutubeThumbnail.find(
          (ele) => ele.name == String(obj.strapiId),
        ).childImageSharp,
      });
      return result;
    }, []);

  const getSaleHeroImage = (sale, isMobile, typeOfProgram, data) => {
    if (isMobile) {
      const image = getImage(data.heroImageMobile.childImageSharp);

      return (
        <div className="relative mt-6">
          <GatsbyImage
            loading="eager"
            image={image}
            placeholder="blurred"
            alt="Hero"
          />
        </div>
      );
    } else {
      const image = getImage(data.heroImageDesktop.childImageSharp);

      return (
        <div className="relative">
          <GatsbyImage
            loading="eager"
            image={image}
            placeholder="blurred"
            alt="Hero"
          />
        </div>
      );
    }
  };

  const settings = {
    slidesPerView: isMobile ? 1 : 3,
    id: 'projectsCarousel',
    loop: true,
    spaceBetween: 30,
    speed: 300,
    grabCursor: true,
    noSwiping: false,
    preloadImages: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    observer: true,
    observeParents: true,
  };

  const hash = typeof window !== 'undefined' && window?.location?.hash;

  return (
    <>
      <SEO title={data.SEOTitle} description={data.SEODescription}></SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Apply & Start Free"
            buttonLocation={LEAD_GEN_AD_PAGE}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <section className="main-section bg-crio-neutral-100">
        {/* Header Section */}
        <div className="fixed-header">
          <div className="navbar subtitle1 scrolled flex items-center justify-center md:justify-between md:px-3 lg:px-6">
            <Link to="/">
              <div
                className="logo"
                onClick={() => {
                  setTimeout(
                    () => window.scrollTo({ top: 0, behavior: 'smooth' }),
                    200,
                  );
                }}
              >
                <StaticImage
                  loading="eager"
                  src="../../images/Crio_Dark.png"
                  placeholder="none"
                  alt="Crio"
                />
                {/* <Image filename={'Crio_Dark.png'}></Image> */}
              </div>
            </Link>
            <div className="hidden md:block">
              <LeadGenerationButton
                buttonLocation={LEAD_GEN_AD_PAGE}
                type="Navigation"
              />
            </div>
          </div>
        </div>
        {/* Hero Section */}
        <section className="relative overflow-hidden bg-crio-neutral-100 md:max-h-[570px]">
          <div className="-mt-32 flex pt-36 pb-12 md:-mt-0 md:pt-16">
            <div className="flex flex-col">
              <div className="flex flex-col justify-center px-3 pt-20 pb-5 text-center   text-crio-green-700 md:pt-10 md:pl-20 md:pr-10 md:text-left ">
                <div className="flex">
                  <h1
                    className={`${styles.customFont} mb-2 hidden text-left text-3xl leading-normal md:block md:text-4xl`}
                  >
                    <RawHTML>{data.heroTitle}</RawHTML> With{' '}
                    <span className="relative text-crio-yellow-700">
                      Real Work Experience
                      <StaticImage
                        loading="eager"
                        src="../../images/adpage/Desktop-border.png"
                        placeholder="none"
                        alt="Underline"
                        className={`absolute -bottom-1 right-0 w-full`}
                      />
                    </span>
                    .
                  </h1>
                  {/* Mobile title */}
                  <h1
                    className={`${styles.customFont} mb-2 block text-left text-3xl leading-normal md:hidden md:text-4xl`}
                  >
                    {data.heroTitle} With{' '}
                    <span className="relative text-crio-yellow-700">
                      Real
                      <StaticImage
                        loading="eager"
                        src="../../images/adpage/Mobile-border.png"
                        placeholder="blurred"
                        alt="Underline"
                        className={`absolute -bottom-1 right-0 w-full`}
                      />
                    </span>
                    <span className="text-crio-yellow-700">
                      Work Experience
                    </span>
                    .
                  </h1>
                  <div className="-mr-16 -mt-8 block md:hidden">
                    {getSaleHeroImage(sale, false, typeOfProgram, data)}
                  </div>
                </div>
                <div className="mt-2 text-left leading-loose text-black">
                  <RawHTML>{data.heroDescription}</RawHTML>
                </div>
                <LeadGenerationButton
                  buttonLocation={LEAD_GEN_AD_PAGE}
                  className="h-[46px] w-full px-12 py-2 md:mb-2 md:w-auto"
                  wrapperClassName="w-full mt-4"
                />
              </div>
            </div>{' '}
            <div className="-mt-2 hidden w-full max-w-[600px] md:flex">
              {getSaleHeroImage(sale, false, typeOfProgram, data)}
            </div>
          </div>
        </section>{' '}
        {/* Companies Section */}
        <section className="relative flex w-full flex-col items-center justify-center bg-crio-neutral-75 p-10 py-20 text-center text-crio-neutral-900">
          <div className="absolute -top-12 left-1/2 -translate-x-1/2 md:left-20 md:mb-4 md:translate-x-0"></div>
          <div className="h-full w-full">
            <h1
              className={`${styles.customFont} mb-2 text-center text-3xl leading-normal text-crio-green-700 md:text-4xl`}
            >
              Crio Grads <br /> have Cracked their Dream Careers In
            </h1>
            <div className="my-6">
              <div className="flex items-center justify-center">
                <div className="overflow-scroll md:overflow-hidden">
                  <div
                    className="grid grid-cols-5 items-center justify-center gap-2 md:gap-y-4 md:gap-x-8"
                    style={{ width: '800px' }}
                  >
                    {companyImages.map((companyImage) => {
                      const image = getImage(
                        companyImage.image.childImageSharp,
                      );

                      return (
                        <div
                          className={`w-28 ${styles.companyImages} ${
                            companyImage.title === 'Jio'
                              ? 'mr-12 w-20 justify-self-center md:mr-0'
                              : ''
                          }`}
                          key={companyImage.title}
                        >
                          <GatsbyImage
                            loading="lazy"
                            image={image}
                            placeholder="blurred"
                            alt={'Company'}
                            className="w-full"
                            imgStyle={{ objectFit: 'contain' }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center">and many more...</p>
        </section>
        <section className=" bg-gradient-to-b from-crio-green-700 to-crio-green-600">
          <div className="pt-16 pb-2">
            <div className=" text-center  text-white ">
              {' '}
              <div className="container mb-20  ">
                <h2 className={`${styles.heading} relative mb-8`}>
                  Join 1000+ ambitious developers{' '}
                  <div className={styles.sketchParent}>
                    <div ref={ref} id="sketch" className="w-[300px]">
                      <div>
                        {' '}
                        <StaticImage
                          loading="lazy"
                          src="../images/fellowship/sketch.png"
                          placeholder="blurred"
                          alt="Sketch"
                          className={styles.sketch}
                        />
                      </div>
                    </div>
                  </div>
                  who've <div className="hidden md:block"></div>taken their
                  career to new heights with Crio
                </h2>
                <div
                  className={` ${styles.button} flex flex-wrap items-center justify-center`}
                >
                  <LeadGenerationButton
                    buttonLocation={LEAD_GEN_AD_PAGE}
                    className="h-[46px] w-full px-12 py-2 md:mb-2 md:w-auto"
                    wrapperClassName="w-full flex justify-center"
                  />
                </div>
              </div>
              <div className="mb-20">
                <div className="hidden md:block">
                  <FellowshipCarousel
                    data={testimonialQuotesData}
                    isMobile={false}
                  ></FellowshipCarousel>
                </div>
                <div className="block md:hidden">
                  <FellowshipCarousel
                    data={testimonialQuotesData}
                    isMobile={true}
                  ></FellowshipCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative flex w-full items-center justify-center bg-crio-neutral-100 p-10 py-20 text-center text-crio-neutral-900 md:p-16 md:py-20">
          <div className="flex w-full flex-col justify-between gap-4 md:flex-row">
            <div className="flex flex-col justify-center gap-4 text-left md:w-3/5">
              <h1
                className={`${styles.customFont} mb-2 text-center text-3xl leading-normal text-crio-green-700 md:text-left md:text-left md:text-4xl`}
              >
                <RawHTML>{data.highlightsTitle}</RawHTML>
              </h1>
              <RawHTML>{data.highlightsDescription}</RawHTML>

              <LeadGenerationButton
                buttonLocation={LEAD_GEN_AD_PAGE}
                className="h-[46px] w-full px-12 py-2 md:mb-2 md:w-auto"
                wrapperClassName="w-full mt-4"
              />
            </div>
            <div className="flex items-center justify-center md:w-2/5">
              <div className="md:w-3/4">
                <StaticImage
                  loading="lazy"
                  src="../../images/adpage/FDT.png"
                  placeholder="blurred"
                  alt="FDT"
                  className="w-full"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="techShow bg-crio-neutral-75 p-4 pb-20 md:flex md:flex-col md:items-center md:justify-center md:p-20">
          <h1
            className={`${styles.customFont} mb-2 text-center text-3xl leading-normal text-crio-green-700 md:text-4xl`}
          >
            <RawHTML>{data.projectsTitle}</RawHTML>
          </h1>
          <div className="relative md:max-w-[1000px]">
            <Swiper
              {...settings}
              className={`projectCarousal py-4`}
              navigation={true}
            >
              {data.projectCards.map((project) => {
                const image = getImage(project.image.childImageSharp);

                return (
                  <SwiperSlide key={project.title}>
                    <div className="flex flex-col items-center justify-between gap-4 rounded-lg bg-crio-neutral-100 px-4 py-6 shadow-lg">
                      <span
                        className={`rounded-full ${
                          project.tag === 'Guided Project'
                            ? 'bg-crio-green-600 text-crio-green-150'
                            : 'bg-crio-green-two-10 text-crio-green-700'
                        } px-3 py-2 text-xs`}
                      >
                        {project.tag}
                      </span>
                      <div>
                        <GatsbyImage
                          loading="lazy"
                          image={image}
                          placeholder="blurred"
                          alt={'Learning'}
                          imgStyle={{ objectFit: 'contain' }}
                          className="h-full w-full"
                        />
                      </div>
                      <div className="text-center text-crio-green-700">
                        <p className="text-xs">
                          <RawHTML>{project.subtitle}</RawHTML>
                        </p>
                        <h3 className={`${styles.customFont} text-xl`}>
                          {project.title}
                        </h3>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <p className="text-center">And many more projects...</p>
          <LeadGenerationButton
            buttonLocation={LEAD_GEN_AD_PAGE}
            className="h-[46px] w-full px-12 py-2 md:mb-2 md:w-auto"
            wrapperClassName="w-full mt-4 flex justify-center"
          />
        </section>
        <span id={EAnchorIds.TECHNOLOGIES} className="anchor"></span>
        <section className="techShow relative bg-crio-neutral-100 py-20 md:pt-20">
          <div className="absolute -top-12 left-1/2 -translate-x-1/2 md:mb-4"></div>
          <h2
            className={`py-5 text-crio-green-700 ${styles.customFont} text-center text-3xl md:text-4xl`}
          >
            <RawHTML>{data.technologiesTitle}</RawHTML>
          </h2>
          <section className="container hidden lg:block">
            <div className="mb-16 flex flex-wrap justify-center">
              {pageData.technologies.map((element) => {
                const image = getImage(element.image.localFile);

                return (
                  <div
                    className="flex flex-col items-center justify-center"
                    key={element.name}
                  >
                    <div className="m-6 mb-2 h-24 w-24 rounded-full">
                      <GatsbyImage
                        loading="lazy"
                        imgStyle={{ objectFit: 'contain' }}
                        image={image}
                        alt={'Technologies'}
                      />
                    </div>
                    <p className="text-center text-crio-neutral-700">
                      {element.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </section>
          <section className="block lg:hidden">
            <CarouselMobile data={pageData.technologies} />
            <div
              className={` ${styles.customFont} container mx-auto mt-5 mb-3 flex flex-col flex-wrap items-center justify-center md:flex-row`}
            ></div>
          </section>{' '}
          <span id={EAnchorIds.CAREER_SERVICES} className="anchor"></span>
          <LeadGenerationButton
            buttonLocation={LEAD_GEN_AD_PAGE}
            className="h-[46px] w-full px-12 py-2 md:mb-2 md:w-auto"
            wrapperClassName="w-full mt-4 flex justify-center"
          />
        </section>
        <section className="relative bg-crio-neutral-75 pb-8 md:pt-20">
          <div className="pt-20  ">
            <div className="container text-center">
              <h2
                className={`${styles.customFont} mb-2 text-center text-3xl leading-normal text-crio-green-700 md:text-4xl`}
              >
                <RawHTML>{data.benefitsTitle}</RawHTML>
              </h2>

              <div className="mb-10 flex  justify-center md:mb-14">
                <RawHTML>{data.benefitsDescription}</RawHTML>
              </div>

              <div className="container">
                <div className=" row  mb-10 leading-7">
                  {data.benefits.map((benefit) => {
                    const image = getImage(benefit.image.childImageSharp);

                    return (
                      <div
                        className="col-6 col-md-3 flex p-2 md:flex-col"
                        key={benefit.title}
                      >
                        <div className=" rounded-md bg-crio-neutral-200 p-4 text-left md:mr-3 md:bg-transparent md:p-0">
                          <div className="mb-4 mr-4 md:mr-0 ">
                            <GatsbyImage
                              loading="lazy"
                              image={image}
                              placeholder="blurred"
                              alt="Why Crio"
                              imgStyle={{ objectFit: 'contain' }}
                            />
                          </div>
                          <h5
                            className={`font-semibold ${styles.customFont} mb-3 text-crio-green-900`}
                          >
                            <RawHTML>{benefit.title}</RawHTML>
                          </h5>

                          <p className="text-sm leading-loose">
                            <RawHTML>{benefit.description}</RawHTML>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <LeadGenerationButton
            buttonLocation={LEAD_GEN_AD_PAGE}
            className="h-[46px] w-full px-12 py-2 md:mb-2 md:w-auto"
            wrapperClassName="w-full mt-4 flex justify-center"
          />
        </section>
        <section className="relative flex w-full items-center justify-center bg-crio-green-600 p-10 py-20 text-center md:p-16 md:py-20"></section>
      </section>
      <style>
        {`
    .projectCarousal .swiper-button-prev {
        background-image: url(${ArrowLeft}) !important;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }

    .projectCarousal .swiper-button-next {
        background-image: url(${ArrowRight}) !important;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }

    .projectCarousal .swiper-button-prev::after {
        content: '';
    }

    .projectCarousal .swiper-button-next::after {
        content: '';
    }

    .logo {
        display: inline-block;
        font-size: 22px;
        margin: 5px 20px;
        width: 130px;
    }
        
    .navbar {
        left: 0;
        right: 0;
        background-color: transparent;
        z-index: 3;
        padding: 5px 0px;
        box-sizing: border-box;
        height:65px;
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
    }

    .navbar.scrolled {
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background-color: #fff;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
    }
      `}
      </style>
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    adPagesYaml(id: { eq: $id }) {
      slug
      primaryProgramID
      typeform

      heroImageMobile {
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      heroImageDesktop {
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      SEODescription
      SEOTitle
      heroTitle
      heroDescription
      companyImages {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 170
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      highlightsTitle
      highlightsDescription

      projectsTitle
      projectCards {
        title
        subtitle
        tag
        image {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      technologiesTitle

      benefitsTitle
      benefitsDescription

      benefits {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              placeholder: BLURRED
              width: 76
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
