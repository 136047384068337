import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// Component to show an element at bottom of the page when scrolled
export default function ScrollButtonComponent({ component, startAt }) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [allowed, setAllowed] = useState(false);
  const [y, setY] = useState(0);
  const scrollRef = useRef(null);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      let timeout = scrollRef.current;

      if (y > window.scrollTop) {
        setAllowed(true);
        setScrollDirection('up');
      } else if (y < window.scrollTop) {
        setAllowed(false);
        setScrollDirection('down');
      }
      setIsScrolling(true);

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 200);

      setY(window.scrollTop);

      if (
        window.scrollTop > startAt &&
        !(
          window.innerHeight + window.scrollTop >=
          document.body.offsetHeight - 500
        )
      ) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
    },
    [y, startAt],
  );

  useEffect(() => {
    setY(document.body.scrollTop);
    document.body.addEventListener('scroll', handleNavigation);

    return () => {
      document.body.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    setY(document.body.scrollTop);
  }, []);

  return (
    <AnimatePresence>
      {(!isScrolling || scrollDirection === 'up') && allowed ? (
        <div className="flex justify-center">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: 'tween', stiffness: 100 }}
            exit={{ y: 100, opacity: 0 }}
            className="fixed bottom-5 z-[1000] flex w-3/4 justify-center text-center md:hidden"
          >
            {component}
          </motion.div>
        </div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  );
}
