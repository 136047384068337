import React from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'swiper/css/bundle';
import './index.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);

function Card(props) {
  const image = getImage(props.imageUrl);
  const name = props.name;

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="m-6 mb-0 h-20 w-20 rounded-full">
          {/* <Image filename={image} /> */}
          <GatsbyImage loading="lazy" image={image} alt={'Image'} />
        </div>
        <p className="text-center text-crio-neutral-700">{name}</p>
      </div>
    </>
  );
}

export default function CarouselMobile(props) {
  const settings = {
    slidesPerView: 3,
    slidesPerColumn: 2,
    slidesPerColumnFill: 'row',
    // navigation: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },

    autoplay: {
      delay: 1500,
      disableOnInteraction: true,
    },
  };

  return (
    <>
      <Swiper
        {...settings}
        className="techCarousel mx-2"
        pagination={{ clickable: true }}
      >
        {props.data.map((element, idx) => {
          return (
            <SwiperSlide key={idx}>
              <Card imageUrl={element.image.localFile} name={element.name} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
